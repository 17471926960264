import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useMemo, useState } from "react";
import Conversation from "../../domain/entities/conversation";
import Summary from "../../domain/entities/summary";
import ConversationsViewModel from "../viewModels/conversationsViewModel";

export const useConversationsViewModel = (token: string) => {
  const { user } = useAuth0();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [stones, setStones] = useState<Summary[]>([]);
  const viewModel = useMemo(() => new ConversationsViewModel(token), [token]);

  const getConversations = useCallback(async () => {
    if (!user) return;
    const conversation = await viewModel.getConversations();
    setConversations(conversation);
  }, [viewModel, user]);

  const getStones = useCallback(async () => {
    if (!user) return;
    const result = await viewModel.getStones();
    console.log(result);
    setStones(result);
  }, [viewModel, user]);

  const createConversation = useCallback(
    async (stoneID: string) => {
      if (!user) return;
      const conversation = await viewModel.createConversation(stoneID);
      setConversations([...conversations, conversation]);
    },
    [viewModel, user, conversations]
  );

  const deleteConversation = useCallback(
    async (stoneID: string, conversationID: string) => {
      await viewModel.deleteConversation(stoneID, conversationID);
      setConversations(conversations.filter((c) => c.id !== conversationID));
    },
    [viewModel, conversations]
  );

  return {
    getConversations,
    getStones,
    createConversation,
    deleteConversation,
    stones,
    conversations,
  };
};
