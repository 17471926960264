import EndpointDocumentation from "./EndpointDocumentation";

const GetTrainingDetails = () => {
  const data = {
    title: "Dettagli addestramento",
    path: "GET /projects/<projectId>/trainings/<trainingId>",
    description: "Ottiene gli identificativi dei training di un progetto",
    responseBody: {
      trainings: [
        {
          id: "training id",
          name: "training name",
          training_data: ["id_training_1", "id_training_2"],
        },
      ],
    },
    params: [
      {
        name: "projectId",
        description:
          "Rappresenta l'ID del progetto per la quale si vuole ottenere la lista dei training",
      },
      {
        name: "trainingId",
        description:
          "Rappresenta l'ID del training per il quale si vuole ottenere i dettagli",
      },
    ],
  };

  return <EndpointDocumentation {...data} />;
};

export default GetTrainingDetails;
