import { Box, Heading, Text } from "@chakra-ui/react";

const AdvantageCard = ({ title, description }: AdvantageCardProps) => {
  return (
    <Box>
      <Heading size="xl" py={5} color="blue.700">
        {title}
      </Heading>
      <Text>{description}</Text>
    </Box>
  );
};

interface AdvantageCardProps {
  title: string;
  description: string;
}

export default AdvantageCard;
