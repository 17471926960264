import { Divider, Flex, Heading, Text } from "@chakra-ui/react";

import CreateNewConversation from "../components/CreateNewConversation";
import GetProjectConversation from "../components/GetProjectConversation";
import GetConversationHistory from "../components/GetConversationHistory";
import GetAllConversations from "../components/GetAllConversation";
import SendMessageToConversation from "../components/SendMessageToConversation";

const ConvestationDocumentationView = () => {
  const description = `Le conversazioni in Vega rappresentano interazioni specifiche tra
        l'utente e l'assistente virtuale all'interno di un progetto.
        Ogni conversazione è un flusso di domande e risposte tra l'utente e
        l'assistente, fornendo un contesto che aiuta l'assistente a
        comprendere e rispondere in modo più accurato alle domande
        dell'utente. L'API di Vega offre gli strumenti per gestire
        queste conversazioni, permettendo di avviare, continuare e terminare
        conversazioni, così come di recuperare lo storico delle conversazioni
        per un'analisi successiva.`;

  return (
    <Flex direction="column">
      <Heading py={5}>Conversazioni</Heading>
      <Text>{description}</Text>
      <Divider my={5} />
      <CreateNewConversation />
      <GetProjectConversation />
      <GetConversationHistory />
      <GetAllConversations />
      <SendMessageToConversation />
    </Flex>
  );
};

export default ConvestationDocumentationView;
