import "../../../assets/css/bubble.css";

const ChatBubble = ({ message, isSender }: ChatBubbleProps) => {
  const bubbleClass = isSender ? "chat-bubble sender" : "chat-bubble receiver";
  const arrowColor = isSender ? "#319795" : "#A0AEC0";

  return (
    <div className={bubbleClass}>
      <div className="chat-content">
        <p>{message}</p>
      </div>
      <div className="chat-arrow">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M0,15 Q5,15 10,0 L20,15 Z" fill={arrowColor} />
        </svg>
      </div>
    </div>
  );
};

interface ChatBubbleProps {
  message: string;
  isSender: boolean;
}

export default ChatBubble;
