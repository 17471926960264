import Training from "./training";

type Stone = {
  id: string;
  name: string;
  description: string;
  trainingSet: Training[];
};

export const stoneExample: Stone = {
  id: "",
  name: "",
  description: "",
  trainingSet: [],
};

export default Stone;
