import {
  Box,
  Flex,
  VStack,
  Text,
  HStack,
  Button,
  Image,
  Heading,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/img/logo.png";

const PromoSection = () => {
  return (
    <Flex w="100vw" h="calc(100vh - 120px)" alignItems="center" px={20}>
      <Box w="60%">
        <VStack align="start">
          <Heading
            size="4xl"
            color="blue.700"
            bgGradient="linear(to-l, #7928CA, #FF0080)"
            bgClip="text"
          >
            Crea. Addestra. Interroga.
          </Heading>
          <Text>
            Vega è una piattaforma AI che permette di creare assistenti virtuali
            personalizzati, facilmente integrabili in qualsiasi applicazione
            grazie a una API intuitiva. Vega apprende dai tuoi documenti,
            offrendo risposte precise e pertinenti, rivoluzionando{" "}
            {`l'interazione`}
            con i contenuti testuali.
          </Text>
          <HStack pt={10}>
            <Link to="/dashboard">
              <Button variant="solid" backgroundColor="blue.500" color="white">
                Accedi
              </Button>
            </Link>
            <Link to="/docs/overview">
              <Button variant="outline">Scopri ora</Button>
            </Link>
          </HStack>
        </VStack>
      </Box>
      <Box flex={1} />
      <Box>
        <Image src={logo} alt="logo" width="350px" />
      </Box>
    </Flex>
  );
};

export default PromoSection;
