import Port from "../../core/app/port";
import Conversation from "../../domain/entities/conversation";
import Message from "../../domain/entities/message";

class GetConversationAdapter
  implements Port<GetConversationResult, Conversation>
{
  process(input: GetConversationResult): Conversation {
    const messages: Message[] = input.messages.map((message) => {
      return {
        id: message.id,
        timestamp: message.timestamp,
        content: message.content,
        role: message.role,
      };
    });

    // FIXME: The title should be customizable
    return {
      id: input.id,
      title: "Conversation",
      projectId: input.projectId,
      messages: messages,
    };
  }
}

export type GetConversationResult = {
  id: string;
  projectId: string;
  messages: responseMessage[];
};

type responseMessage = {
  id: string;
  role: string;
  content: string;
  timestamp: string;
};

export default GetConversationAdapter;
