import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";
import {
  Box,
  Divider,
  Flex,
  FlexProps,
  Heading,
  Icon,
  VStack,
  Wrap,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactText } from "react";
import { v4 as uuidv4 } from "uuid";

const Sidebar = ({ topItems, bottomItems, width }: SidebarProps) => {
  const renderItems = (items: Array<LinkItemProps>) => {
    return items.map((link) => (
      <NavLink to={link.path} key={uuidv4()}>
        {({ isActive }) => (
          <SidebarItem
            icon={link.icon}
            className={isActive ? "active-link" : ""} // Aggiungi questa riga
          >
            {link.name}
          </SidebarItem>
        )}
      </NavLink>
    ));
  };

  const renderGroups = (groups: Array<SidebarGroup>) => {
    return groups.map((group) => (
      <Box my={2} mx={5} key={uuidv4()}>
        {group.title && (
          <Wrap>
            <Heading size="sm" mb={2}>
              {group.title}
            </Heading>
            <Divider orientation="horizontal" />
          </Wrap>
        )}
        {renderItems(group.links)}
      </Box>
    ));
  };

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", sm: width, md: width, lg: width, xl: width }}
      pos="fixed"
      h="full"
    >
      <Flex as="nav" direction="column" height="calc(100% - 7rem)" pt={5}>
        <VStack alignItems="start">
          <Flex direction="column" width="100%">
            {renderGroups(topItems)}
          </Flex>
        </VStack>
        <Box flex="1" />
        <VStack alignItems="start">
          <Divider orientation="horizontal" />
          <Flex direction="column" width="100%">
            {renderGroups(bottomItems)}
          </Flex>
        </VStack>
      </Flex>
    </Box>
  );
};

const SidebarItem = ({ icon, children, className, ...rest }: NavItemProps) => {
  return (
    <Flex
      align="center"
      py="2"
      cursor="pointer"
      className={className}
      sx={{
        "&.active-link": {
          color: "blue.600", // Scegli il colore del testo desiderato quando il link è attivo
        },
      }}
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="16"
          _groupHover={{
            color: "white",
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  );
};

interface NavItemProps extends FlexProps {
  icon?: IconType;
  children: ReactText;
  className?: string;
}

interface SidebarProps {
  topItems: Array<SidebarGroup>;
  bottomItems: Array<SidebarGroup>;
  width: number;
}

export interface LinkItemProps {
  name: string;
  icon?: IconType;
  path: string;
}

export interface SidebarGroup {
  title?: string;
  links: Array<LinkItemProps>;
}

export default Sidebar;
