import PricingCard from "../../../../components/PricingCard";

const BasicPricingCard = () => {
  const data = {
    title: "Basic",
    price: "11.99 €/mese",
    description: "200 messaggi/mese",
    features: [
      {
        name: "2 Progetti",
        isAvailable: true,
      },
      {
        name: "1 Addestamento per progetto",
        isAvailable: true,
      },
      {
        name: "200 Messaggi al mese",
        isAvailable: true,
      },
      {
        name: "Accesso API",
        isAvailable: true,
      },
    ],
  };

  return <PricingCard {...data} />;
};

export default BasicPricingCard;
