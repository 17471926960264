import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Input,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";

const CreateNewStoneModal = ({
  isOpen,
  onClose,
  onConfirm,
}: CreateNewStoneModalProps) => {
  const [name, setName] = useState("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value);

  const handleOnConfirm = () => {
    onConfirm(name);
    setName("");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Crea nuovo progetto</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="8px">Inserisci il titolo del progetto</Text>
          <Input placeholder="Titolo" value={name} onChange={handleChange} />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="solid"
            colorScheme="teal"
            onClick={() => handleOnConfirm()}
          >
            Salva
          </Button>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Chiudi
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface CreateNewStoneModalProps {
  isOpen: boolean;
  onConfirm: (name: string) => void;
  onClose: () => void;
}

export default CreateNewStoneModal;
