import EndpointDocumentation from "./EndpointDocumentation";

const CreateProject = () => {
  const data = {
    title: "Crea un nuovo progetto",
    path: "POST /projects",
    description: "Permette la creazione di un nuovo progetto.",
    responseBody: {
      name: "project name",
    },
    requestBody: {
      id: "xyz",
      name: "project name",
    },
  };

  return <EndpointDocumentation {...data} />;
};

export default CreateProject;
