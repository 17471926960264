import UseCase from "../../core/app/usecase";
import StoneRepository from "../repositories/stone";

class DeleteStone implements UseCase<string, void> {
  private repository: StoneRepository;

  constructor(repository: StoneRepository) {
    this.repository = repository;
  }

  Call(input: string): Promise<void> {
    return this.repository.delete(input);
  }
}

export default DeleteStone;
