import UseCase from "../../core/app/usecase";
import Training from "../entities/training";
import StoneRepository from "../repositories/stone";

export interface TrainStoneInput {
  id: string;
  name: string;
  file: File;
}

class TrainStone implements UseCase<TrainStoneInput, Training> {
  private repository: StoneRepository;

  constructor(repository: StoneRepository) {
    this.repository = repository;
  }

  async Call(data: TrainStoneInput): Promise<Training> {
    return this.repository.train(data.id, data.file, data.name);
  }
}

export default TrainStone;
