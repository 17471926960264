import { useContext, useEffect } from "react";
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  VStack,
  Tbody,
  Td,
  Text,
  Box,
  SimpleGrid,
  Button,
  Flex,
  Spacer,
  TableCaption,
  useDisclosure,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { FiCopy, FiPlus, FiSettings, FiTrash2 } from "react-icons/fi";

import { AuthContext } from "../../../providers/Auth0JWTProvider";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import ProcessingLabel from "../../../components/ProcessingLabel";
import LoadingView from "../../public/views/LoadingView";
import CreateNewStoneModal from "../components/CreateNewStoneModal";
import DeleteStoneAlert from "../components/DeleteStoneAlert";
import { useProjectsViewModel } from "../../../hooks/stonesViewModel";

const StonesView = () => {
  const createStoneDialog = useDisclosure();
  const deleteStoneAlert = useDisclosure();
  const auth = useContext(AuthContext);

  const {
    createProject,
    getAllProjects,
    projects,
    deleteProject,
    toDelete,
    setToDelete,
    copyIDToClipboard,
    isProcessing,
  } = useProjectsViewModel(auth.token);

  const onCreateProject = (name: string) => {
    createProject(name);
    createStoneDialog.onClose();
  };

  const onDeleteProject = () => {
    deleteProject(toDelete);
    deleteStoneAlert.onClose();
  };

  const onConfirmDelete = (id: string) => {
    setToDelete(id);
    deleteStoneAlert.onOpen();
  };

  useEffect(() => {
    if (auth.token != "") {
      getAllProjects();
    }
  }, [getAllProjects, auth.token]);

  const renderProjects = () => {
    return projects.map((project) => {
      return (
        <Tr key={project.id}>
          <Td color="blue">
            <VStack alignItems="start">
              <RouterLink to={`/dashboard/projects/${project.id}`}>
                {project.name}
              </RouterLink>
              <Text fontSize="xs" color="gray.500">
                {project.id}
              </Text>
            </VStack>
          </Td>
          <Td>Lorem ipsum</Td>
          <Td textAlign="right">
            <HStack spacing={1} justifyContent="flex-end" minWidth="100%">
              <RouterLink to={`/dashboard/projects/${project.id}`}>
                <IconButton aria-label="Settings" icon={<FiSettings />} />
              </RouterLink>
              <IconButton
                aria-label="Copy ID"
                icon={<FiCopy />}
                onClick={() => copyIDToClipboard(project.id!)}
              />
              <IconButton
                aria-label="Delete"
                icon={<FiTrash2 />}
                onClick={() => onConfirmDelete(project.id!)}
              />
            </HStack>
          </Td>
        </Tr>
      );
    });
  };

  return (
    <Flex minWidth="max-content" alignItems="center" gap="2">
      <Box w="100%">
        <VStack>
          <SimpleGrid w="100%">
            <Box>
              <Flex>
                <VStack alignItems="start">
                  <Text fontSize="2xl" fontWeight="bold">
                    I tuoi progetti
                  </Text>
                  <ProcessingLabel
                    isProcessing={isProcessing}
                    text="Caricamento in corso..."
                  />
                </VStack>
                <Spacer />
                <Button
                  colorScheme="teal"
                  size="md"
                  onClick={createStoneDialog.onOpen}
                  leftIcon={<FiPlus />}
                >
                  Crea nuovo progetto
                </Button>
              </Flex>
            </Box>
            <Box>
              <TableContainer w="100%" bg="white" mt={5}>
                <Table variant="simple" width="100%" colorScheme="whiteAlpha">
                  <TableCaption>
                    Questi sono tutti i progetti che hai creato
                  </TableCaption>
                  <Thead borderBottomWidth="2px" borderBottomColor="gray.200">
                    <Tr>
                      <Th>Nome</Th>
                      <Th>Descrizione</Th>
                      <Th textAlign="end">Azioni</Th>
                    </Tr>
                  </Thead>
                  <Tbody>{renderProjects()}</Tbody>
                </Table>
              </TableContainer>
            </Box>
          </SimpleGrid>
        </VStack>
      </Box>
      <CreateNewStoneModal
        {...createStoneDialog}
        onConfirm={(name: string) => {
          onCreateProject(name);
        }}
      />
      <DeleteStoneAlert {...deleteStoneAlert} onConfirm={onDeleteProject} />
    </Flex>
  );
};

export default withAuthenticationRequired(StonesView, {
  onRedirecting: () => <LoadingView />,
});
