import EndpointDocumentation from "../components/EndpointDocumentation";

const GetProjectConversation = () => {
  const data = {
    title: "Lista delle conversazioni di un progetto",
    path: "GET /projects/<projectId>/conversations",
    description:
      "Permette di ottenere tutte le conversazioni presenti all'interno di un progetto.",
    responseBody: {
      chats: [
        {
          id: "xyz",
          projectId: "xyz",
        },
      ],
    },
    params: [
      {
        name: "projectId",
        description:
          "Rappresenta l'ID del progetto per la quale si vole ottenere le conversazioni",
      },
    ],
  };

  return <EndpointDocumentation {...data} />;
};

export default GetProjectConversation;
