import { Box, Flex, Heading, SimpleGrid } from "@chakra-ui/react";
import StarterPricingCard from "./StartedPricingCard";
import BasicPricingCard from "./BasicPricingCard";
import ProPricingCard from "./ProPricingCard";

const PricingSection = () => {
  return (
    <Flex bgColor="blue.300" w="100vw" px={20} py={10} direction="column">
      <Box flex={1} p={5}>
        <Heading color="white" pb={5} size="3xl" textAlign="center">
          Prezzi
        </Heading>
      </Box>
      <Box flex={1} p={5}>
        <SimpleGrid columns={3} spacing={10}>
          <StarterPricingCard />
          <BasicPricingCard />
          <ProPricingCard />
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

export default PricingSection;
