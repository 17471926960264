import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Box, Flex, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import TopBar from "../../../components/TopBar";

import { useContext, useEffect } from "react";

import Summary from "../../../../domain/entities/summary";
import { AuthContext } from "../../../providers/Auth0JWTProvider";
import LoadingView from "../../public/views/LoadingView";
import ConversationSidebar from "../components/ConversationSidebar";
import ConversationDetails from "../components/ConversationDetails";
import CreateConversationDialog from "../components/CreateConversationDialog";
import { useConversationsViewModel } from "../../../hooks/conversationsViewViewModel";

const ConversationsView = () => {
  const createConversationDialog = useDisclosure();
  const auth = useContext(AuthContext);

  const {
    getConversations,
    getStones,
    createConversation,
    conversations,
    stones,
  } = useConversationsViewModel(auth.token);

  useEffect(() => {
    if (auth.token != "") {
      getConversations();
    }
  }, [getConversations, auth.token]);

  const handleOpenCreateConversationDialog = () => {
    getStones().then(() => createConversationDialog.onOpen());
  };

  return (
    <Box bg={useColorModeValue("gray.100", "gray.900")} width="100%">
      <Flex direction="column">
        <Box>
          <TopBar />
        </Box>
        <Box>
          <ConversationSidebar
            conversations={conversations}
            openDialog={handleOpenCreateConversationDialog}
            stones={stones}
          />
          <ConversationDetails />
          <CreateConversationDialog
            {...createConversationDialog}
            stones={stones}
            onConfirm={(stone: Summary) => createConversation(stone.id!)}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default withAuthenticationRequired(ConversationsView, {
  onRedirecting: () => <LoadingView />,
});
