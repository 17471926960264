import EndpointDocumentation from "./EndpointDocumentation";

const GetAllProjects = () => {
  const data = {
    title: "Ottieni tutti i progetti",
    path: "GET /projects",
    description: "Ottiene la lista di tutti i progetti attivi",
    responseBody: {
      projects: [
        {
          id: "project id",
          name: "project name",
        },
      ],
    },
  };

  return <EndpointDocumentation {...data} />;
};

export default GetAllProjects;
