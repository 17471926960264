import axios from "axios";
import Conversation from "../../domain/entities/conversation";
import ConversationRepository from "../../domain/repositories/conversation";
import GetConversationAdapter, {
  GetConversationResult,
} from "../adapters/getConversation";
import GetAllConversationsAdapter, {
  GetAllConversationsResult,
} from "../adapters/getAllConversations";
import CreateConversationAdapter, {
  CreateConversationResult,
} from "../adapters/createConversation";

class ConversationRepositoryImpl implements ConversationRepository {
  authToken: string;

  constructor(token: string) {
    this.authToken = token;
  }

  private getAuthorizationHeader() {
    return {
      headers: {
        authorization: `Bearer ${this.authToken}`,
      },
    };
  }

  async getConversation(id: string): Promise<Conversation> {
    const headers = this.getAuthorizationHeader();
    const response = await axios.get<GetConversationResult>(
      `${process.env.REACT_APP_VEGA_API_URL}/conversations/${id}`,
      { ...headers }
    );

    const port = new GetConversationAdapter();
    return port.process(response.data);
  }

  async getUserConversations(): Promise<Conversation[]> {
    const headers = this.getAuthorizationHeader();
    const response = await axios.get<GetAllConversationsResult>(
      `${process.env.REACT_APP_VEGA_API_URL}/conversations`,
      { ...headers }
    );

    const port = new GetAllConversationsAdapter();
    return port.process(response.data);
  }

  async createConversation(stoneID: string): Promise<Conversation> {
    const headers = this.getAuthorizationHeader();
    console.log("headers", headers);
    const response = await axios.post<CreateConversationResult>(
      `${process.env.REACT_APP_VEGA_API_URL}/projects/${stoneID}/conversations`,
      {},
      { ...headers }
    );

    const port = new CreateConversationAdapter();
    return port.process({
      ...response.data,
      stone_id: stoneID,
    });
  }

  async deleteConversation(stoneID: string, id: string): Promise<void> {
    const headers = this.getAuthorizationHeader();
    axios.delete(
      `${process.env.REACT_APP_VEGA_API_URL}/projects/${stoneID}/conversations/${id}`,
      { ...headers }
    );
  }
}

export default ConversationRepositoryImpl;
