import {
  FiBook,
  FiDatabase,
  FiMessageCircle,
  FiSettings,
} from "react-icons/fi";
import Sidebar, { SidebarGroup } from "../../../components/Sidebar";

const LinkItems: Array<SidebarGroup> = [
  {
    title: "Menu",
    links: [
      { name: "Progetti", icon: FiDatabase, path: "/dashboard/projects" },
      {
        name: "Conversazioni",
        icon: FiMessageCircle,
        path: "/conversations",
      },
    ],
  },
];

const BottomLinkItems: Array<SidebarGroup> = [
  {
    links: [
      { name: "Impostazioni", icon: FiSettings, path: "/dashboard/settings" },
      { name: "Documentazione", icon: FiBook, path: "/docs/overview" },
    ],
  },
];

const DashboardSidebar = ({ width }: DashboardSidebarProps) => {
  return (
    <Sidebar topItems={LinkItems} bottomItems={BottomLinkItems} width={width} />
  );
};

interface DashboardSidebarProps {
  width: number;
}

export default DashboardSidebar;
