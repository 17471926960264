import { Heading, Text } from "@chakra-ui/react";
import EndpointDocumentation from "./EndpointDocumentation";

const TrainProject = () => {
  const data = {
    title: "Addestra un progetto",
    path: "POST /projects/<projectId>/trainings",
    description: "Ottiene gli identificativi dei training di un progetto",
    responseBody: {
      trainingId: "training id",
      vectors_ids: ["id_training_1", "id_training_2"],
    },
    params: [
      {
        name: "projectId",
        description:
          "Rappresenta l'ID del progetto per la quale si vuole ottenere la lista dei training",
      },
    ],
  };

  return (
    <EndpointDocumentation {...data}>
      <Heading size="xs" py={5}>
        FormData
      </Heading>
      <Text>
        <Text as="samp">document</Text>
        <Text>Contiene il file PDF utilizzato per {`l'addestramento`}</Text>
      </Text>
    </EndpointDocumentation>
  );
};

export default TrainProject;
