import PricingCard from "../../../../components/PricingCard";

const StarterPricingCard = () => {
  const data = {
    title: "Starter",
    price: "Gratis",
    description: "15 messaggi/mese",
    features: [
      {
        name: "1 Progetto",
        isAvailable: true,
      },
      {
        name: "1 Addestamento",
        isAvailable: true,
      },
      {
        name: "25 Messaggi al mese",
        isAvailable: true,
      },
      {
        name: "Accesso API",
        isAvailable: false,
      },
    ],
  };

  return <PricingCard {...data} />;
};

export default StarterPricingCard;
