import EndpointDocumentation from "../components/EndpointDocumentation";

const SendMessageToConversation = () => {
  const data = {
    title: "Invia un messaggio ad una conversazione",
    path: "POST /conversations/<conversationId>/messages",
    description: "Invia un messaggio ad una conversazione",
    requestBody: {
      text: "message content",
    },
    responseBody: {
      answer: "answer content",
    },
    params: [
      {
        name: "conversationId",
        description: "Rappresenta l'ID della conversazione",
      },
    ],
  };

  return <EndpointDocumentation {...data} />;
};

export default SendMessageToConversation;
