import Port from "../../core/app/port";
import Conversation from "../../domain/entities/conversation";

class GetAllConversationsAdapter
  implements Port<GetAllConversationsResult, Conversation[]>
{
  process(input: GetAllConversationsResult): Conversation[] {
    return input.chats.map((chat) => {
      return {
        id: chat.id,
        title: "Conversation",
        projectId: chat.project_id,
        messages: [],
      };
    });
  }
}

export type GetAllConversationsResult = {
  chats: responseChat[];
};

type responseChat = {
  id: string;
  project_id: string;
};

export default GetAllConversationsAdapter;
