import { Flex, useColorModeValue, Heading, Box } from "@chakra-ui/react";

const ConversationTopBar = ({ title }: ConversationTopBarProps) => {
  return (
    <Box>
      <Flex
        align="center"
        justify="space-between"
        w="100%"
        p="4"
        bg={useColorModeValue("white", "white")}
        borderBottomWidth={1}
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Heading size="md">{title}</Heading>
      </Flex>
    </Box>
  );
};

interface ConversationTopBarProps {
  title: string;
}

export default ConversationTopBar;
