import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const IntroductionView = () => {
  return (
    <Flex direction="column">
      <Heading py={5}>Introduzione</Heading>
      <Text>
        Puoi interagire con le API di Vega utilizzando qualsiasi linguaggio
        grazie alle seguenti REST API.
      </Text>
      <SimpleGrid columns={2} spacing={5} my={5}>
        <Box>
          <Link to="/docs/authentication">
            <Card>
              <CardHeader>
                <Heading size="md">Autenticazione</Heading>
              </CardHeader>
              <CardBody>
                <Text fontSize="sm">
                  Gestisci {`l'autenticazione`} e {`l'accesso`} alle API
                </Text>
              </CardBody>
            </Card>
          </Link>
        </Box>
        <Box>
          <Link to="/docs/projects">
            <Card>
              <CardHeader>
                <Heading size="md">Crea e gestisci i progetti</Heading>
              </CardHeader>
              <CardBody>
                <Text fontSize="sm">
                  Visualizza le API per gestire i progetti
                </Text>
              </CardBody>
            </Card>
          </Link>
        </Box>
        <Box>
          <Link to="/docs/conversations">
            <Card>
              <CardHeader>
                <Heading size="md">Crea e gestisci le conversazioni</Heading>
              </CardHeader>
              <CardBody>
                <Text fontSize="sm">
                  Visualizza le API per gestire le conversazioni
                </Text>
              </CardBody>
            </Card>
          </Link>
        </Box>
        <Box>
          <Link to="/docs/trainings">
            <Card>
              <CardHeader>
                <Heading size="md">Addestra i tuoi progetti</Heading>
              </CardHeader>
              <CardBody>
                <Text fontSize="sm">
                  Visualizza le API per addestrare i progetti
                </Text>
              </CardBody>
            </Card>
          </Link>
        </Box>
      </SimpleGrid>
    </Flex>
  );
};

export default IntroductionView;
