import Stone from "../../domain/entities/stone";
import Summary from "../../domain/entities/summary";
import Training from "../../domain/entities/training";
import StoneRepository from "../../domain/repositories/stone";
import { GetAllProjectFailure } from "../../core/app/failure";
import GetAllStoneAdapter from "../adapters/getAllStone";
import axios from "axios";

class StoneRepositoryImpl implements StoneRepository {
  authToken: string;

  constructor(token: string) {
    this.authToken = token;
  }
  async getTrainings(id: string): Promise<Training[]> {
    const headers = this.getAuthorizationHeader();
    const response = await axios.get(
      `${process.env.REACT_APP_VEGA_API_URL}/projects/${id}/trainings`,
      { ...headers }
    );

    return response.data.trainings;
  }

  private getAuthorizationHeader() {
    return {
      headers: {
        authorization: `Bearer ${this.authToken}`,
      },
    };
  }

  async getAll(): Promise<Summary[]> {
    try {
      const headers = this.getAuthorizationHeader();
      const response = await axios.get(
        `${process.env.REACT_APP_VEGA_API_URL}/projects`,
        {
          ...headers,
        }
      );

      const port = new GetAllStoneAdapter();
      return port.process(response.data);
    } catch (error) {
      throw GetAllProjectFailure;
    }
  }

  async get(id: string): Promise<Stone> {
    const headers = this.getAuthorizationHeader();
    const res = await axios.get<Stone>(
      `${process.env.REACT_APP_VEGA_API_URL}/projects/${id}`,
      {
        ...headers,
      }
    );
    return res.data;
  }

  async create(project: Summary): Promise<Summary> {
    const headers = this.getAuthorizationHeader();
    const res = await axios.post(
      `${process.env.REACT_APP_VEGA_API_URL}/projects`,
      {
        name: project.name,
      },
      { ...headers }
    );

    return {
      id: res.data.id,
      name: project.name,
    };
  }

  async delete(id: string): Promise<void> {
    try {
      const headers = this.getAuthorizationHeader();
      await axios.delete(
        `${process.env.REACT_APP_VEGA_API_URL}/projects/${id}`,
        {
          ...headers,
        }
      );
    } catch (error) {
      throw new Error("Failed to delete project");
    }
  }

  async train(id: string, file: File, name: string): Promise<Training> {
    const data = new FormData();

    data.append("document", file);
    data.append("name", name);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VEGA_API_URL}/projects/${id}/trainings`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${this.authToken}`,
          },
        }
      );

      return {
        id: response.data.training_id,
        name: response.data.name,
      };
    } catch (error) {
      throw new Error(`Request failed: ${error}`);
    }
  }

  async forgetTraining(stoneID: string, trainingID: string): Promise<void> {
    try {
      const headers = this.getAuthorizationHeader();
      await axios.delete(
        `${process.env.REACT_APP_VEGA_API_URL}/projects/${stoneID}/trainings/${trainingID}`,
        { ...headers }
      );
    } catch (error) {
      throw new Error(`Request failed: ${error}`);
    }
  }
}

export default StoneRepositoryImpl;
