import Stone from "../../domain/entities/stone";
import Summary from "../../domain/entities/summary";
import Training from "../../domain/entities/training";
import GetStoneSummary from "../../domain/interactors/getStone";
import GetStoneTrainings, {
  GetStoneTrainingsInput,
} from "../../domain/interactors/getStoneTrainings";
import StoneRepository from "../../domain/repositories/stone";

class GetStoneWithTrainings {
  private repo: StoneRepository;

  constructor(repo: StoneRepository) {
    this.repo = repo;
  }

  public async execute(id: string): Promise<Stone> {
    const summary = await this.getStoneSummary(id);
    const trainings = await this.getStoneTrainings(id);

    return {
      id: id,
      name: summary.name,
      description: summary.description || "",
      trainingSet: trainings || [],
    };
  }

  private async getStoneSummary(id: string): Promise<Summary> {
    const uc = new GetStoneSummary(this.repo);
    return await uc.Call(id);
  }

  private async getStoneTrainings(id: string): Promise<Training[]> {
    const uc = new GetStoneTrainings(this.repo);
    const input: GetStoneTrainingsInput = { stoneID: id };

    return await uc.Call(input);
  }
}

export default GetStoneWithTrainings;
