import { Divider, Flex, Heading, Text } from "@chakra-ui/react";

import CreateProject from "../components/CreateProject";
import GetAllProjects from "../components/GetAllProjects";
import GetProjectDetails from "../components/GetProjectDetails";
import DeleteProject from "../components/DeleteProject";

const ProjectDocumentationView = () => {
  return (
    <Flex direction="column">
      <Heading py={5}>Progetti</Heading>
      <Text>
        I progetti in Vega rappresentano unità di apprendimento individuali per{" "}
        {`l'assistente`} virtuale. Ciascun progetto può essere addestrato
        separatamente, consentendo la personalizzazione {`dell'assistente`} in
        base ai documenti caricati. Grazie a questa struttura, è possibile
        creare assistenti virtuali specializzati in differenti argomenti o
        settori, ognuno dei quali può evolvere e apprendere in maniera
        indipendente.
        {`L'API`} di Vega fornisce tutti gli strumenti necessari per creare,
        gestire e addestrare i progetti, rendendo semplice {`l'implementazione`}{" "}
        e{`l'integrazione`} {`dell'assistente`} virtuale {`all'interno`} di
        qualsiasi applicazione.
      </Text>
      <Divider my={5} />
      <CreateProject />
      <GetAllProjects />
      <GetProjectDetails />
      <DeleteProject />
    </Flex>
  );
};

export default ProjectDocumentationView;
