import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";

const DeleteStoneAlert = ({
  isOpen,
  onClose,
  onConfirm,
}: DeleteStoneAlertProps) => {
  const cancelRef = useRef(null);

  const handleDelete = () => {
    onConfirm();
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Elimina projetto
          </AlertDialogHeader>

          <AlertDialogBody>
            Sei sicuro di voler eliminare questo progetto?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancella
            </Button>
            <Button colorScheme="red" onClick={handleDelete} ml={3}>
              Elimina
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeleteStoneAlert;

interface DeleteStoneAlertProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
