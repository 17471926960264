import EndpointDocumentation from "./EndpointDocumentation";

const DeleteProject = () => {
  const data = {
    title: "Elimina un progetto",
    path: "DELETE /projects/<projectId>",
    description: "Permette di eliminare il progetto specificato",
    responseBody: {
      message: "success",
    },
    params: [
      {
        name: "projectId",
        description: "Rappresenta l'ID del progetto da eliminare",
      },
    ],
  };

  return <EndpointDocumentation {...data} />;
};

export default DeleteProject;
