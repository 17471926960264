import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { FiPaperclip } from "react-icons/fi";

const TrainStoneDialog = ({
  isOpen,
  onClose,
  onConfirm,
  onFileChange,
  onNameChange,
  selectedFile,
  trainingName,
}: TrainStoneDialogProps) => {
  const handleOnConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Crea una nuova incisione</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} alignItems="start">
            <Text mb="8px">Inserisci il nome</Text>
            <Input type="text" onChange={onNameChange} value={trainingName} />
            <Text mb="8px">Seleziona il file di addestramento</Text>
            <Input
              type="file"
              accept="application/pdf"
              onChange={onFileChange}
            />
            {selectedFile && (
              <Text fontSize="sm">
                <FiPaperclip /> {selectedFile.name}
              </Text>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="solid"
            colorScheme="teal"
            onClick={() => handleOnConfirm()}
          >
            Salva
          </Button>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Chiudi
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface TrainStoneDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedFile: File | null;
  trainingName: string;
}

export default TrainStoneDialog;
