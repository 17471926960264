import UseCase from "../../core/app/usecase";
import Conversation from "../entities/conversation";
import ConversationRepository from "../repositories/conversation";

export interface GetConversationInput {
  id: string;
}

class GetConversation implements UseCase<GetConversationInput, Conversation> {
  private repository: ConversationRepository;

  constructor(repository: ConversationRepository) {
    this.repository = repository;
  }

  Call(input: GetConversationInput): Promise<Conversation> {
    return this.repository.getConversation(input.id);
  }
}

export default GetConversation;
