import {
  Box,
  Code,
  Divider,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import ReactJson from "react-json-view";
import { v4 as uuidv4 } from "uuid";

const EndpointDocumentation = (props: EndpointDocumentationProps) => {
  const handleRenderRequest = () => {
    if (props.requestBody) {
      return (
        <Box>
          <Heading size="sm" py={5}>
            Richiesta
          </Heading>
          <ReactJson src={props.requestBody} name={false} theme="monokai" />
        </Box>
      );
    }
  };

  const handleRenderParams = () => {
    if (props.params) {
      return props.params.map((param) => {
        return (
          <Box key={uuidv4()}>
            <Box py={2}>
              <Text as="samp">{param.name}</Text>
              <Text>{param.description}</Text>
            </Box>
          </Box>
        );
      });
    }
  };

  return (
    <Box>
      <SimpleGrid columns={2} spacing={10}>
        <Box>
          <Heading size="md" py={5}>
            {props.title}
          </Heading>
          <Code>{props.path}</Code>
          <Text my={5}>{props.description}</Text>
          {props.params && <Heading size="xs">Parametri</Heading>}
          {handleRenderParams()}
          {props.children}
        </Box>
        <Box>
          {handleRenderRequest()}
          <Box>
            <Heading size="sm" py={5}>
              Risposta
            </Heading>
            <ReactJson src={props.responseBody} name={false} theme="monokai" />
          </Box>
        </Box>
      </SimpleGrid>
      <Divider my={5} />
    </Box>
  );
};

export interface EndpointDocumentationProps {
  title: string;
  description: string;
  path: string;
  requestBody?: object;
  responseBody: object;
  params?: Params[];
  children?: React.ReactNode;
}

interface Params {
  name: string;
  description: string;
}

export default EndpointDocumentation;
