import React from "react";
import { createRoot } from "react-dom/client";
import "./presentation/assets/css/index.css";
import { ChakraProvider } from "@chakra-ui/react";
import AppRouter from "./presentation/router/AppRouter";
import Auth0ProviderWithNavigate from "./presentation/providers/AuthProviderWithNavigate";
import { BrowserRouter } from "react-router-dom";
import { Auth0JWTProvider } from "./presentation/providers/Auth0JWTProvider";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <Auth0JWTProvider>
          <ChakraProvider>
            <AppRouter />
          </ChakraProvider>
        </Auth0JWTProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>
);
