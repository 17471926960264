import PricingCard from "../../../../components/PricingCard";

const ProPricingCard = () => {
  const data = {
    title: "Profesional",
    price: "29,99 €/mese",
    description: "700 messaggi/mese",
    features: [
      {
        name: "5 Progetti",
        isAvailable: true,
      },
      {
        name: "5 Addestamento per progetto",
        isAvailable: true,
      },
      {
        name: "700 Messaggi al mese",
        isAvailable: true,
      },
      {
        name: "Accesso API",
        isAvailable: true,
      },
    ],
  };

  return <PricingCard {...data} />;
};

export default ProPricingCard;
