import EndpointDocumentation from "../components/EndpointDocumentation";

const GetAllConversations = () => {
  const data = {
    title: "Ottenere tutte le conversazioni",
    path: "GET /conversations",
    description: "Permette di ottenere tutte le conversazioni",
    responseBody: {
      chats: [
        {
          id: "xyz",
          projectId: "xyz",
        },
      ],
    },
  };

  return <EndpointDocumentation {...data} />;
};

export default GetAllConversations;
