import UseCase from "../../core/app/usecase";
import StoneRepository from "../repositories/stone";

export interface ForgetTrainingInput {
  stoneID: string;
  trainingID: string;
}

class ForgetTraining implements UseCase<ForgetTrainingInput, void> {
  private repository: StoneRepository;

  constructor(repository: StoneRepository) {
    this.repository = repository;
  }

  async Call(data: ForgetTrainingInput): Promise<void> {
    return this.repository.forgetTraining(data.stoneID, data.trainingID);
  }
}

export default ForgetTraining;
