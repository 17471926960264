import EndpointDocumentation from "./EndpointDocumentation";

const DeleteTraining = () => {
  const data = {
    title: "Rimuove un addestramento",
    path: "DELETE /projects/<projectId>/trainings/<trainingId>",
    description: "Elimina un addestramento di un progetto",
    responseBody: {
      message: "Training deleted successfully",
    },
    params: [
      {
        name: "projectId",
        description:
          "Rappresenta l'ID del progetto per la quale si vuole ottenere la lista dei training",
      },
      {
        name: "trainingId",
        description: "Rappresenta l'ID del training da eliminare",
      },
    ],
  };

  return <EndpointDocumentation {...data} />;
};

export default DeleteTraining;
