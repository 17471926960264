import { Box, Center, Flex, Text } from "@chakra-ui/react";

const ApiView = () => {
  return (
    <Box bg="gray.100" width="100%">
      <Flex direction="column">
        <Box>
          <Center>
            <Text size="xl">Coming soon...</Text>
          </Center>
        </Box>
      </Flex>
    </Box>
  );
};

export default ApiView;
