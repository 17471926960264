import { useContext, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Spacer,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { FiEdit3, FiTrash2, FiX } from "react-icons/fi";
import { AuthContext } from "../../../providers/Auth0JWTProvider";
import ProcessingLabel from "../../../components/ProcessingLabel";
import TrainStoneDialog from "../components/TrainStoneDialog";
import ForgetTrainingDialog from "../components/ForgetTrainingAlert";
import { useStoneDetailsViewModel } from "../../../hooks/stoneDetailsViewModel";

const StoneDetailsView = () => {
  const { id } = useParams();
  const auth = useContext(AuthContext);
  const {
    getStone,
    handleFileChange,
    uploadFile,
    selectedFile,
    forgetTraining,
    setToForget,
    handleNameChange,
    trainingName,
    isProcessing,
    isLoading,
    stone,
  } = useStoneDetailsViewModel(auth.token);
  const trainStoneDialog = useDisclosure();
  const forgetTrainingDialog = useDisclosure();

  useEffect(() => {
    if (id && auth.token != "") {
      getStone(id);
    }
  }, [id, getStone, auth.token]);

  const handleForgetTraining = (id: string) => {
    setToForget(id);
    forgetTrainingDialog.onOpen();
  };

  const renderStoneTraining = () => {
    return stone?.trainingSet?.map((training) => {
      return (
        <Tr key={training.id}>
          <Td>{training.name}</Td>
          <Td>{training.id}</Td>
          <Td textAlign="end">
            <HStack spacing={1} justifyContent="flex-end" minWidth="100%">
              <IconButton
                aria-label="Delete"
                icon={<FiTrash2 />}
                onClick={() => handleForgetTraining(training.id)}
              />
            </HStack>
          </Td>
        </Tr>
      );
    });
  };

  return (
    <Flex minWidth="max-content" alignItems="center" gap="2">
      <VStack width="100%" alignItems="start">
        <Box backgroundColor="white" p={5} width="100%">
          <Flex>
            <VStack alignItems="start" spacing={2}>
              <Heading size="md">{stone?.name}</Heading>
              <ProcessingLabel isProcessing={isLoading} text="Caricamento..." />
            </VStack>
            <Spacer />
            <NavLink to="/dashboard/projects">
              <IconButton
                colorScheme="red"
                aria-label="Delete"
                icon={<FiX />}
              />
            </NavLink>
          </Flex>
        </Box>
        <Box backgroundColor="white" p={5} width="100%">
          <Box>
            <Flex>
              <VStack alignItems="start">
                <Heading size="md">Addestramenti effettuati</Heading>
                <ProcessingLabel
                  isProcessing={isProcessing}
                  text="Addestramento in corso..."
                />
              </VStack>
              <Spacer />
              <Button
                colorScheme="teal"
                size="md"
                onClick={() => {
                  trainStoneDialog.onOpen();
                }}
                leftIcon={<FiEdit3 />}
              >
                Nuovo addestramento
              </Button>
            </Flex>
          </Box>
          <TableContainer w="100%" bg="white" mt={5}>
            <Table variant="simple" width="100%" colorScheme="whiteAlpha">
              <TableCaption>
                Questi sono tutti i progetti che hai creato
              </TableCaption>
              <Thead borderBottomWidth="2px" borderBottomColor="gray.200">
                <Tr>
                  <Th>Nome</Th>
                  <Th>ID</Th>
                  <Th textAlign="end">Azioni</Th>
                </Tr>
              </Thead>
              <Tbody>{renderStoneTraining()}</Tbody>
            </Table>
          </TableContainer>
        </Box>
      </VStack>
      <TrainStoneDialog
        {...trainStoneDialog}
        onFileChange={handleFileChange}
        onNameChange={handleNameChange}
        trainingName={trainingName}
        selectedFile={selectedFile}
        onConfirm={uploadFile}
      />
      <ForgetTrainingDialog
        {...forgetTrainingDialog}
        onConfirm={forgetTraining}
      />
    </Flex>
  );
};

export default StoneDetailsView;
