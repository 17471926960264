import { v4 as uuidv4 } from "uuid";
import Message from "../../domain/entities/message";
import Conversation from "../../domain/entities/conversation";
import GetConversation, {
  GetConversationInput,
} from "../../domain/interactors/getConversation";
import ConversationRepositoryImpl from "../../infrastructure/repositories/CoversationRepositoryImpl";

import ConversationRepository from "../../domain/repositories/conversation";

class ConversationDetailViewModel {
  private repository: ConversationRepository;

  constructor(token: string) {
    this.repository = new ConversationRepositoryImpl(token);
  }

  async getConversation(conversationID: string): Promise<Conversation> {
    const input: GetConversationInput = {
      id: conversationID,
    };

    const uc = new GetConversation(this.repository);
    return await uc.Call(input);
  }

  private createConversationMessage(message: string): Message {
    return {
      id: uuidv4(),
      content: message,
      role: "user",
      timestamp: Date.now().toString(),
    };
  }
}

export default ConversationDetailViewModel;
