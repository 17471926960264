import { Box, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

const ClassicLayout = ({ topbar }: ClassicLayoutProps) => {
  return (
    <Box height="100vh" overflow="hidden">
      <Box
        //height="60px"
        backgroundColor="gray.200"
        position="fixed"
        width="100%"
        zIndex="docked"
      >
        {topbar}
      </Box>
      <Flex direction="row" pt="100px">
        <Box
          pt="4"
          pr="4"
          p={5}
          overflowY="auto"
          height="calc(100vh - 115px)"
          flex="1"
        >
          <Outlet />
        </Box>
      </Flex>
    </Box>
  );
};

interface ClassicLayoutProps {
  topbar: React.ReactNode;
}

export default ClassicLayout;
