import UseCase from "../../core/app/usecase";
import Conversation from "../entities/conversation";
import ConversationRepository from "../repositories/conversation";

export interface GetStoneConversationsInput {
  conversationID: string;
}

class GetStoneConversations
  implements UseCase<GetStoneConversationsInput, Conversation[]>
{
  private repository: ConversationRepository;

  constructor(repository: ConversationRepository) {
    this.repository = repository;
  }

  Call(): Promise<Conversation[]> {
    return this.repository.getUserConversations();
  }
}

export default GetStoneConversations;
