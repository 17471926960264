import EndpointDocumentation from "./EndpointDocumentation";

const CreateNewConversation = () => {
  const data = {
    title: "Crea una nuova conversazione",
    path: "POST /projects/<projectId>/conversations",
    description:
      "Permette la creazione di una nuova conversazione all'interno di un progetto.",
    responseBody: {
      conversationId: "xyz",
    },
    params: [
      {
        name: "projectId",
        description:
          "Rappresenta l'ID del progetto per la quale si vuole creare una nuova conversazione",
      },
    ],
  };

  return <EndpointDocumentation {...data} />;
};

export default CreateNewConversation;
