import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useWebSocket from "react-use-websocket";
import Conversation from "../../domain/entities/conversation";
import ConversationDetailViewModel from "../viewModels/conversationDetailViewModel";
import { AuthContext } from "../providers/Auth0JWTProvider";
import { v4 as uuidv4 } from "uuid";
import Message from "../../domain/entities/message";

export const useConversationDetailViewModel = (chatID: string) => {
  const auth = useContext(AuthContext);
  const viewModel = useMemo(
    () => new ConversationDetailViewModel(auth.token),
    [auth]
  );

  const [url] = useState<string>(
    `${process.env.REACT_APP_VEGA_WS_URL}/conversations/${chatID}/ws`
  );

  const [conversation, setConversation] = useState<Conversation>();

  const { sendMessage, lastMessage } = useWebSocket(url);

  const getConversation = useCallback(
    async (conversationID: string) => {
      const result = await viewModel.getConversation(conversationID);
      setConversation(result);
    },
    [viewModel]
  );

  useEffect(() => {
    if (chatID && auth.token != "") {
      getConversation(chatID);
    }
  }, [getConversation, chatID, auth.token]);

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      const message: Message = {
        id: uuidv4(),
        content: data.message.content,
        role: data.message.role,
        timestamp: new Date().toISOString(),
      };

      setConversation((prev) => {
        if (prev) {
          return {
            ...prev,
            messages: [...prev.messages, message],
          };
        }

        return prev;
      });
    }
  }, [lastMessage, setConversation]);

  return {
    conversation,
    sendMessage,
    lastMessage,
  };
};
