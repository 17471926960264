import { Avatar, Box, HStack, Text, VStack } from "@chakra-ui/react";
import Summary from "../../../../domain/entities/summary";

const ConversationItem = ({ stone }: ConversationItemProps) => {
  return (
    <Box
      _hover={{
        backgroundColor: "blue.50",
        color: "blue.700",
      }}
      //mx={2}
      borderBottomWidth={1}
      borderBottomStyle="solid"
    >
      <HStack px={5} py={2}>
        <Avatar size="md" name={stone.name} src="https://bit.ly/broken-link" />
        <VStack align="start" spacing={0}>
          <Text fontWeight="bold">{stone.name}</Text>
          <Text fontSize="xs">{stone.description}</Text>
        </VStack>
      </HStack>
    </Box>
  );
};

export default ConversationItem;

interface ConversationItemProps {
  stone: Summary;
}
