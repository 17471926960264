import { Route, Routes } from "react-router-dom";

import ApiView from "../view/panel/views/ApiView";
import StoneDetailsView from "../view/panel/views/StoneDetailsView";

import DocumentationView from "../view/public/views/DocumentationView";

import ConvestationDocumentationView from "../view/documentation/views/ConversationDocumentationView";
import ProjectDocumentationView from "../view/documentation/views/ProjectDocumentationView";
import ConversationContentView from "../view/panel/views/ConversationContentView";
import MainView from "../view/public/views/MainView";
import HomeView from "../view/public/views/HomeView";
import TrainingConversationDocumentationView from "../view/documentation/views/TrainingConversationDocumentationView";
import DashboardView from "../view/panel/views/DashboardView";
import ConversationsView from "../view/panel/views/ConversationsView";
import StonesView from "../view/panel/views/StonesView";
import IntroductionView from "../view/documentation/views/IntroductionView";
import AuthenticationView from "../view/documentation/views/AuthenticationView";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<MainView />}>
        <Route path="/" element={<HomeView />} />
      </Route>
      <Route path="/docs" element={<DocumentationView />}>
        <Route path="overview" element={<IntroductionView />} />
        <Route path="authentication" element={<AuthenticationView />} />
        <Route
          path="conversations"
          element={<ConvestationDocumentationView />}
        />
        <Route path="projects" element={<ProjectDocumentationView />} />
        <Route
          path="trainings"
          element={<TrainingConversationDocumentationView />}
        />
      </Route>
      <Route path="/dashboard" element={<DashboardView />}>
        <Route path="projects" element={<StonesView />} />
        <Route path="projects/:id" element={<StoneDetailsView />} />
        <Route path="settings" element={<ApiView />} />/
      </Route>
      <Route path="/conversations" element={<ConversationsView />}>
        <Route path=":id" element={<ConversationContentView />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
