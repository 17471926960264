import { Box, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

const ConversationDetails = () => {
  return (
    <Box
      ml={{ base: 0, sm: 300, md: 300, lg: 300, xl: 300 }}
      h="100%"
      pos="fixed"
      width="calc(100% - 300px)"
      bg="gray.100"
    >
      <Flex direction="column" height="100%" w="100%">
        <Outlet />
      </Flex>
    </Box>
  );
};

export default ConversationDetails;
