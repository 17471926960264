import {
  Box,
  Flex,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { FiPaperclip, FiSend, FiUploadCloud } from "react-icons/fi";

const ConversationMessageBar = ({ onSend }: ConversationMessageBarProps) => {
  const [message, setMessage] = useState("");

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  const handleSend = () => {
    if (!message) return;
    onSend(message);
    setMessage("");
  };

  return (
    <Box>
      <Flex
        align="center"
        justify="space-between"
        w="100%"
        p={4}
        bg={useColorModeValue("white", "white")}
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Input
          placeholder="Scrivi un messaggio"
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
        />
        <IconButton
          aria-label="Invia"
          icon={<FiSend />}
          variant="ghost"
          onClick={handleSend}
        />
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FiPaperclip />}
            variant="ghost"
          />
          <MenuList>
            <MenuItem icon={<FiUploadCloud />}>
              Invia file di addestramento
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};

interface ConversationMessageBarProps {
  onSend: (message: string) => void;
}

export default ConversationMessageBar;
