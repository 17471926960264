import EndpointDocumentation from "./EndpointDocumentation";

const GetProjectDetails = () => {
  const data = {
    title: "Dettaglio progetto",
    path: "GET /projects/<projectId>",
    description: "Ottiene i dettagli relativi ad un progetto",
    responseBody: {
      projects: {
        id: "project id",
        name: "project name",
      },
    },
    params: [
      {
        name: "projectId",
        description:
          "Rappresenta l'ID del progetto per la quale si vuole creare una nuova conversazione",
      },
    ],
  };

  return <EndpointDocumentation {...data} />;
};

export default GetProjectDetails;
