import { Flex } from "@chakra-ui/react";
import PricingSection from "../components/pricingSection/PricingSection";
import AdvantagesSection from "../components/advantagesSection/AdvantagesSection";
import UsecasesSection from "../components/usecasesSection/UsecasesSection";
import PromoSection from "../components/promoSection/PromoSection";

const HomeView = () => {
  return (
    <Flex w="100%" justify="center" align="center" direction="column">
      <PromoSection />
      <UsecasesSection />
      <AdvantagesSection />
      <PricingSection />
    </Flex>
  );
};

export default HomeView;
