import UseCase from "../../core/app/usecase";
import Conversation from "../entities/conversation";
import ConversationRepository from "../repositories/conversation";

export interface CreateNewConversationInput {
  stoneID: string;
}

class CreateNewConversation
  implements UseCase<CreateNewConversationInput, Conversation>
{
  private repository: ConversationRepository;

  constructor(repository: ConversationRepository) {
    this.repository = repository;
  }

  Call(input: CreateNewConversationInput): Promise<Conversation> {
    return this.repository.createConversation(input.stoneID);
  }
}

export default CreateNewConversation;
