import Port from "../../core/app/port";
import Summary from "../../domain/entities/summary";

class GetAllStoneAdapter implements Port<GetAllStoneResult, Summary[]> {
  process(data: GetAllStoneResult): Summary[] {
    return data.projects.map((project) => {
      return {
        id: project.id,
        name: project.name,
      };
    });
  }
}

type GetAllStoneResult = {
  projects: Project[];
};

type Project = {
  id: string;
  name: string;
};

export default GetAllStoneAdapter;
