import {
  Flex,
  useColorModeValue,
  Image,
  Menu,
  MenuButton,
  Avatar,
  Button,
  MenuList,
  MenuItem,
  HStack,
  Tag,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { FiChevronDown } from "react-icons/fi";
import { useAuth0 } from "@auth0/auth0-react";

const TopBar = () => {
  const { user, logout } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      w="100%"
      p="4"
      bg={useColorModeValue("gray.50", "gray.800")}
      borderBottomWidth={1}
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
    >
      <Flex justifyContent="flex-start" flexGrow={1}>
        <HStack spacing="4">
          <NavLink to="/dashboard">
            <Image src={logo} alt="Vega" width="80px" />
          </NavLink>
          <Tag colorScheme="orange">Alpha.1</Tag>
        </HStack>
      </Flex>
      <Flex justifyContent="flex-end" flexGrow={1}>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                variant="ghost"
                mr="2"
                rightIcon={<FiChevronDown />}
              >
                <Avatar name={user?.name} src={user?.picture} />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
    </Flex>
  );
};

export default TopBar;
