import { ChangeEvent, useCallback, useMemo, useState } from "react";
import StoneDetailsViewModel from "../viewModels/stoneDetailsViewModel";
import Stone, { stoneExample } from "../../domain/entities/stone";
import Training from "../../domain/entities/training";

export const useStoneDetailsViewModel = (token: string) => {
  const viewModel = useMemo(() => new StoneDetailsViewModel(token), [token]);
  const [stone, setStone] = useState<Stone>(stoneExample);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [trainingName, setTrainingName] = useState<string>("");
  const [toForget, setToForget] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getStone = useCallback(
    async (id: string) => {
      setIsLoading(true);
      const data = await viewModel.getStone(id);
      setIsLoading(false);
      setStone(data);
    },
    [viewModel]
  );

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTrainingName(event.target.value);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert("Per favore, carica solo file PDF.");
    }
  };

  const uploadFile = useCallback(async () => {
    if (!selectedFile || !stone || !trainingName) {
      alert("Per favore, carica un file PDF e seleziona una pietra.");
      return;
    }

    setIsProcessing(true);
    const training = await viewModel.uploadFile(
      stone.id,
      selectedFile,
      trainingName
    );

    const updatedStone = {
      ...stone,
      trainingSet: [...stone.trainingSet, training],
    };

    setSelectedFile(null);
    setIsProcessing(false);
    setStone(updatedStone);
  }, [selectedFile, stone, viewModel, trainingName]);

  const forgetTraining = useCallback(async () => {
    if (!stone || !toForget) {
      alert("Per favore, seleziona una pietra e un training set.");
      return;
    }

    await viewModel.forgetTraining(stone.id, toForget);
    const updatedStone = {
      ...stone,
      trainingSet: stone.trainingSet.filter(
        (training: Training) => training.id !== toForget
      ),
    };

    setStone(updatedStone);
  }, [stone, viewModel, toForget]);

  return {
    getStone,
    handleFileChange,
    uploadFile,
    selectedFile,
    forgetTraining,
    setToForget,
    trainingName,
    handleNameChange,
    stone,
    isProcessing,
    isLoading,
  };
};
