import {
  Box,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import ZStack from "../../../../components/ZStack";
import CircleIcon from "../../../../components/CircleIcon";
import {
  FcAssistant,
  FcAutomotive,
  FcBriefcase,
  FcNeutralDecision,
  FcSearch,
  FcStatistics,
} from "react-icons/fc";

const UsecasesSection = () => {
  return (
    <Flex bgColor="blue.300" w="100vw" px={20} py={10}>
      <Box flex={1} p={10} color="white">
        <Heading color="white" pb={5} size="2xl" textAlign="center">
          Cosa può fare Vega?
        </Heading>
        <SimpleGrid columns={2} spacing={10}>
          <Box py={2}>
            <HStack spacing={5}>
              <Box>
                <ZStack spacing={5}>
                  <CircleIcon boxSize={20} color="white" />
                  <Icon as={FcAssistant} boxSize={10} />
                </ZStack>
              </Box>
              <Box>
                <Heading size="md">Automazione del Servizio Clienti</Heading>
                <Text>
                  Risponde automaticamente alle domande frequenti basandosi
                  sulla documentazione del prodotto.
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box py={2}>
            <HStack spacing={5}>
              <Box>
                <ZStack spacing={5}>
                  <CircleIcon boxSize={20} color="white" />
                  <Icon as={FcSearch} boxSize={10} />
                </ZStack>
              </Box>
              <Box>
                <Heading size="md">Estrazione di Informazioni</Heading>
                <Text>
                  Agisce come strumento di ricerca avanzato in ampi set di
                  documenti testuali.
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box py={2}>
            <HStack spacing={5}>
              <ZStack spacing={5}>
                <CircleIcon boxSize={20} color="white" />
                <Icon as={FcBriefcase} boxSize={10} />
              </ZStack>
              <Box>
                <Heading size="md">Formazione e Istruzione</Heading>
                <Text>
                  Funziona come un tutor virtuale, fornendo risposte dettagliate
                  su materiali didattici.
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box py={2}>
            <HStack spacing={5}>
              <ZStack spacing={5}>
                <CircleIcon boxSize={20} color="white" />
                <Icon as={FcStatistics} boxSize={10} />
              </ZStack>
              <Box>
                <Heading size="md">Monitoraggio dei Media</Heading>
                <Text>
                  Analizza flussi di notizie o social media, identificando temi
                  o sentiment specifici.
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box py={2}>
            <HStack spacing={5}>
              <ZStack spacing={5}>
                <CircleIcon boxSize={20} color="white" />
                <Icon as={FcAutomotive} boxSize={10} />
              </ZStack>
              <Box>
                <Heading size="md">Automazione dei Processi Aziendali</Heading>
                <Text>
                  Si integra nei flussi di lavoro per automatizzare{" "}
                  {`l'interazione`}
                  con i documenti testuali.
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box py={2}>
            <HStack spacing={5}>
              <ZStack spacing={5}>
                <CircleIcon boxSize={20} color="white" />
                <Icon as={FcNeutralDecision} boxSize={10} />
              </ZStack>
              <Box>
                <Heading size="md">Assistente Personale Virtuale</Heading>
                <Text>
                  Fornisce risposte personalizzate e consigli basati su
                  informazioni specifiche.
                </Text>
              </Box>
            </HStack>
          </Box>
        </SimpleGrid>
        <Divider p={5} />
        <Box p={5}>
          <Text fontSize="2xl" align="center">
            Questi sono solo alcuni esempi di quello che Vega può fare. Il
            limite è la tua immaginazione!
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default UsecasesSection;
