import Training from "../../domain/entities/training";
import TrainStone, {
  TrainStoneInput,
} from "../../domain/interactors/trainStone";
import ForgetTraining, {
  ForgetTrainingInput,
} from "../../domain/interactors/forgetTraining";
import StoneRepositoryImpl from "../../infrastructure/repositories/StoneRepositoryImpl";
import StoneRepository from "../../domain/repositories/stone";
import GetStoneWithTrainings from "../../application/mediators/getStoneWithTrainings";

class StoneDetailsViewModel {
  repository: StoneRepository;

  constructor(token: string) {
    this.repository = new StoneRepositoryImpl(token);
  }

  async getStone(id: string) {
    const mediator = new GetStoneWithTrainings(this.repository);
    return await mediator.execute(id);
  }

  async uploadFile(id: string, file: File, name: string): Promise<Training> {
    const uc = new TrainStone(this.repository);
    const input: TrainStoneInput = {
      id: id,
      file: file,
      name: name,
    };

    return await uc.Call(input);
  }

  async forgetTraining(stoneID: string, trainingID: string): Promise<void> {
    const uc = new ForgetTraining(this.repository);
    const input: ForgetTrainingInput = {
      stoneID: stoneID,
      trainingID: trainingID,
    };

    return await uc.Call(input);
  }
}

export default StoneDetailsViewModel;
