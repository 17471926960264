import { Divider, Flex, Heading, Text } from "@chakra-ui/react";
import GetProjectTrainings from "../components/GetProjectTrainings";
import GetTrainingDetails from "../components/GetTrainingDetails";
import TrainProject from "../components/TrainProject";
import DeleteTraining from "../components/DeleteTraining";

const TrainingConversationDocumentationView = () => {
  return (
    <Flex direction="column">
      <Heading py={5}>Addestramento</Heading>
      <Text>
        {`L'addestramento`} in Vega è il processo attraverso il quale{" "}
        {`l'assistente`}
        virtuale apprende e si specializza. Questo avviene grazie ai documenti
        testuali caricati {`dall'utente all'interno`} di un specifico progetto.
        {`L'addestramento`} può essere {`un'attività`} continua, con{" "}
        {`l'assistente`} che si evolve e migliora nel tempo man mano che vengono
        aggiunti nuovi documenti. {`L'API`} di Vega rende semplice gestire
        questo processo, fornendo funzionalità per caricare documenti, avviare
        {` l'addestramento`} e monitorare il suo progresso.
      </Text>
      <Divider my={5} />
      <GetProjectTrainings />
      <GetTrainingDetails />
      <TrainProject />
      <DeleteTraining />
    </Flex>
  );
};

export default TrainingConversationDocumentationView;
