import UseCase from "../../core/app/usecase";
import Training from "../entities/training";
import StoneRepository from "../repositories/stone";

export interface GetStoneTrainingsInput {
  stoneID: string;
}

class GetStoneTrainings implements UseCase<GetStoneTrainingsInput, Training[]> {
  private repository: StoneRepository;

  constructor(repository: StoneRepository) {
    this.repository = repository;
  }

  Call(input: GetStoneTrainingsInput): Promise<Training[]> {
    return this.repository.getTrainings(input.stoneID);
  }
}

export default GetStoneTrainings;
