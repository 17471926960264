import EndpointDocumentation from "../components/EndpointDocumentation";

const GetConversationHistory = () => {
  const data = {
    title: "Contenuto di una conversazione",
    path: "GET /projects/<projectId>/conversations/<conversationId>",
    description: "Permette di ottenere il contenuto di una conversazione",
    responseBody: {
      id: "xyz",
      messages: [
        {
          id: "xyz",
          content: "message content",
          role: "user",
          time: "2021-01-01T00:00:00.000Z",
        },
      ],
    },
    params: [
      {
        name: "projectId",
        description: "Rappresenta l'ID del progetto",
      },
      {
        name: "conversationId",
        description: "Rappresenta l'ID della conversazione",
      },
    ],
  };

  return <EndpointDocumentation {...data} />;
};

export default GetConversationHistory;
