import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import ConversationItem from "./ConversationItem";
import { useState } from "react";
import Summary from "../../../../domain/entities/summary";

const CreateConversationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  stones,
}: CreateConversationDialogProps) => {
  const [selectedStone, setSelectedStone] = useState<Summary | null>(null);
  const handleOnConfirm = () => {
    if (!selectedStone) return;
    onConfirm(selectedStone);
    setSelectedStone(null);
    onClose();
  };

  const renderStones = () => {
    return stones.map((stone: Summary) => (
      <Box
        key={stone.id}
        cursor="pointer"
        onClick={() => setSelectedStone(stone)}
        bg={
          selectedStone && selectedStone.id === stone.id
            ? "blue.50"
            : "transparent"
        }
      >
        <ConversationItem stone={{ id: stone.id, name: stone.name }} />
      </Box>
    ));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Crea una nuova conversazione</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="8px">Seleziona la progetto</Text>
          {renderStones()}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="solid"
            colorScheme="teal"
            onClick={() => handleOnConfirm()}
          >
            Crea
          </Button>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Chiudi
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface CreateConversationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (stone: Summary) => void;
  stones: Summary[];
}

export default CreateConversationDialog;
