import Summary from "../../domain/entities/summary";
import GetAllStones from "../../domain/interactors/getAllStones";
import CreateStone from "../../domain/interactors/createStone";
import DeleteStone from "../../domain/interactors/deleteStone";
import StoneRepositoryImpl from "../../infrastructure/repositories/StoneRepositoryImpl";
import StoneRepository from "../../domain/repositories/stone";

class StonesViewModel {
  repository: StoneRepository;

  constructor(token: string) {
    this.repository = new StoneRepositoryImpl(token);
  }

  async getAllStones(): Promise<Summary[]> {
    const uc = new GetAllStones(this.repository);
    return await uc.Call();
  }

  async createStone(name: string): Promise<Summary> {
    const data: Summary = { name: name };
    const uc = new CreateStone(this.repository);

    return await uc.Call(data);
  }

  async deleteStone(id: string): Promise<void> {
    const uc = new DeleteStone(this.repository);
    return await uc.Call(id);
  }
}

export default StonesViewModel;
