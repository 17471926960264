import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { FcOk } from "react-icons/fc";
import { FiXCircle } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";

const PricingCard = ({
  title,
  price,
  features,
  description,
}: PricingCardProps) => {
  const handleFeature = (feature: Feature) => {
    return feature.isAvailable ? (
      <ListItem key={uuidv4()}>
        <ListIcon as={FcOk} color="green.500" />
        {feature.name}
      </ListItem>
    ) : (
      <ListItem key={uuidv4()}>
        <ListIcon as={FiXCircle} color="red.500" />
        {feature.name}
      </ListItem>
    );
  };

  return (
    <Card>
      <CardHeader>
        <Heading size="xl" color="blue.700">
          {title}
        </Heading>
        <Text as="b" fontSize="2xl" color="blue.500">
          {price}
        </Text>
        <Text fontSize="md">{description}</Text>
        <Button colorScheme="blue" variant="solid" width="100%" my={5}>
          Inizia
        </Button>
      </CardHeader>
      <CardBody>
        <List spacing={3}>
          {features.map((feature) => handleFeature(feature))}
        </List>
      </CardBody>
    </Card>
  );
};

interface PricingCardProps {
  title: string;
  price: string;
  description: string;
  features: Feature[];
}

interface Feature {
  name: string;
  isAvailable: boolean;
}

export default PricingCard;
