import Port from "../../core/app/port";
import Conversation from "../../domain/entities/conversation";

class CreateConversationAdapter
  implements Port<CreateConversationResult, Conversation>
{
  process(input: CreateConversationResult): Conversation {
    // FIXME: Title should be customizable
    return {
      id: input.conversation_id,
      title: "Conversation",
      projectId: input.stone_id,
      messages: [],
    };
  }
}

export type CreateConversationResult = {
  conversation_id: string;
  stone_id: string;
};

export default CreateConversationAdapter;
