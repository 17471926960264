import Conversation from "../../domain/entities/conversation";
import GetStoneConversations from "../../domain/interactors/getStoneConversations";
import GetAllStones from "../../domain/interactors/getAllStones";
import Summary from "../../domain/entities/summary";
import CreateNewConversation, {
  CreateNewConversationInput,
} from "../../domain/interactors/createNewConversation";
import DeleteConversation, {
  DeleteConversationInput,
} from "../../domain/interactors/deleteConversation";
import ConversationRepositoryImpl from "../../infrastructure/repositories/CoversationRepositoryImpl";
import StoneRepositoryImpl from "../../infrastructure/repositories/StoneRepositoryImpl";
import ConversationRepository from "../../domain/repositories/conversation";
import StoneRepository from "../../domain/repositories/stone";

class ConversationsViewModel {
  private conversationRepository: ConversationRepository;
  private stoneRepository: StoneRepository;

  constructor(token: string) {
    this.conversationRepository = new ConversationRepositoryImpl(token);
    this.stoneRepository = new StoneRepositoryImpl(token);
  }

  async getConversations(): Promise<Conversation[]> {
    const uc = new GetStoneConversations(this.conversationRepository);
    return await uc.Call();
  }

  async getStones(): Promise<Summary[]> {
    const uc = new GetAllStones(this.stoneRepository);
    return await uc.Call();
  }

  async createConversation(stoneID: string): Promise<Conversation> {
    const input: CreateNewConversationInput = {
      stoneID: stoneID,
    };

    const uc = new CreateNewConversation(this.conversationRepository);
    const conversation = await uc.Call(input);

    return conversation;
  }

  async deleteConversation(
    stoneID: string,
    conversationID: string
  ): Promise<void> {
    const input: DeleteConversationInput = {
      id: conversationID,
      stoneID: stoneID,
    };
    const uc = new DeleteConversation(this.conversationRepository);
    await uc.Call(input);
  }
}

export default ConversationsViewModel;
