import { useCallback, useMemo, useState } from "react";
import Summary from "../../domain/entities/summary";
import StonesViewModel from "../viewModels/stonesViewModel";

export const useProjectsViewModel = (token: string) => {
  const [projects, setProjects] = useState<Summary[]>([]);
  const [toDelete, setToDelete] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const viewModel = useMemo(() => new StonesViewModel(token), [token]);

  const createProject = useCallback(
    async (name: string) => {
      setIsProcessing(true);
      const newProject = await viewModel.createStone(name);
      setIsProcessing(false);
      setProjects((prevProjects) => [...prevProjects, newProject]);
    },
    [viewModel]
  );

  const getAllProjects = useCallback(async () => {
    setIsProcessing(true);
    const data = await viewModel.getAllStones();
    setIsProcessing(false);
    setProjects([...data]);
  }, [viewModel]);

  const deleteProject = useCallback(
    async (id: string) => {
      await viewModel.deleteStone(id);
      setProjects((prevProjects) =>
        prevProjects.filter((project) => project.id !== id)
      );
    },
    [viewModel]
  );

  const copyIDToClipboard = useCallback(async (id: string) => {
    await navigator.clipboard.writeText(id);
  }, []);

  return {
    getAllProjects,
    createProject,
    deleteProject,
    projects,
    setProjects,
    setToDelete,
    toDelete,
    copyIDToClipboard,
    isProcessing,
  };
};
