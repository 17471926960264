import UseCase from "../../core/app/usecase";
import Stone from "../entities/stone";
import StoneRepository from "../repositories/stone";

class GetStoneSummary implements UseCase<string, Stone> {
  private repository: StoneRepository;

  constructor(repository: StoneRepository) {
    this.repository = repository;
  }

  Call(input: string): Promise<Stone> {
    return this.repository.get(input);
  }
}

export default GetStoneSummary;
