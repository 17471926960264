import SidebarLeftLayout from "../../../layout/SidebarLeftLayout";
import PublicSidebar from "../components/PublicSidebar";
import PublicTopBar from "../components/PublicTopBar";

const DocumentationView = () => {
  const sidebarWidth = 250;

  return (
    <SidebarLeftLayout
      topbar={<PublicTopBar />}
      sidebar={<PublicSidebar width={sidebarWidth} />}
      sidebarWidth={sidebarWidth}
    />
  );
};

export default DocumentationView;
