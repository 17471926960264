import UseCase from "../../core/app/usecase";
import Summary from "../entities/summary";
import StoneRepository from "../repositories/stone";

class CreateStone implements UseCase<Summary, Summary> {
  private repository: StoneRepository;

  constructor(repository: StoneRepository) {
    this.repository = repository;
  }

  async Call(stone: Summary): Promise<Summary> {
    return this.repository.create(stone);
  }
}

export default CreateStone;
