import { Box, HStack, Spinner, Text } from "@chakra-ui/react";

const ProcessingLabel = ({ isProcessing, text }: ProcessingLabelProps) => {
  if (isProcessing) {
    return (
      <HStack>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xs"
        />
        <Text size="xs">{text}</Text>
      </HStack>
    );
  }

  return <Box />;
};

interface ProcessingLabelProps {
  isProcessing: boolean;
  text: string;
}

export default ProcessingLabel;
