import {
  Flex,
  useColorModeValue,
  Image,
  HStack,
  Button,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/img/logo.png";

const PublicTopBar = () => {
  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      w="100%"
      p="4"
      bg={useColorModeValue("gray.50", "gray.800")}
      borderBottomWidth={1}
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
    >
      <Flex justifyContent="flex-start" flexGrow={1}>
        <HStack spacing="10">
          <NavLink to="/">
            <Image src={logo} alt="Vega" maxWidth="80px" />
          </NavLink>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/docs/overview">Overview</NavLink>
          <NavLink to="/docs">Prezzi</NavLink>
        </HStack>
      </Flex>
      <Flex justifyContent="flex-end" flexGrow={1} pr={3}>
        <NavLink to="/dashboard">
          <Button variant="solid" backgroundColor="blue.700" color="white">
            Login
          </Button>
        </NavLink>
      </Flex>
    </Flex>
  );
};

export default PublicTopBar;
