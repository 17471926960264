import { Box, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import AdvantageCard from "./AdvantageCard";

const AdvantagesSection = () => {
  const adaptable = {
    title: "Adattabile",
    description: `Ogni progetto che crei può essere addestrato individualmente,
              permettendo un'interazione personalizzata basata sui documenti
              caricati`,
  };

  const managed = {
    title: "Gestito",
    description: `Avvia, utilizza e scala con facilità, mentre noi ci occupiamo di
              mantenere il sistema efficiente e sicuro.`,
  };

  const dynamic = {
    title: "Dinamico",
    description: `Aggiorna in tempo reale il tuo assistente virtuale ogni volta che
              carichi nuovi documenti, modifichi o rimuovi dati esistenti.`,
  };

  const description = `Vega semplifica la creazione e l'addestramento di assistenti virtuali basati su IA attraverso un'API intuitiva su una piattaforma cloud-native gestita, liberandoti da ogni preoccupazione relativa all'infrastruttura.`;

  return (
    <Flex p={10} direction="column" px={20} py={10}>
      <Heading pb={5} size="2xl" color="blue.700">
        Completamente gestito,
      </Heading>
      <Heading pb={5} size="xl" color="blue.300">
        pronto per la produzione
      </Heading>
      <Text py={5}>{description}</Text>
      <Box py={2} pt={5}>
        <SimpleGrid columns={3} spacing={10}>
          <AdvantageCard {...managed} />
          <AdvantageCard {...adaptable} />
          <AdvantageCard {...dynamic} />
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

export default AdvantagesSection;
