import UseCase from "../../core/app/usecase";
import ConversationRepository from "../repositories/conversation";

export interface DeleteConversationInput {
  id: string;
  stoneID: string;
}

class DeleteConversation implements UseCase<DeleteConversationInput, void> {
  private repository: ConversationRepository;

  constructor(repository: ConversationRepository) {
    this.repository = repository;
  }

  Call(input: DeleteConversationInput): Promise<void> {
    return this.repository.deleteConversation(input.stoneID, input.id);
  }
}

export default DeleteConversation;
