import { useAuth0 } from "@auth0/auth0-react";
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";

export interface AuthJWT {
  token: string;
}

export const AuthContext = createContext<AuthJWT>({
  token: "",
});

interface Auth0JWTProviderProps {
  children: ReactNode;
}

export const Auth0JWTProvider = ({ children }: Auth0JWTProviderProps) => {
  const [auth, setAuth] = useState<AuthJWT>({ token: "" });
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const fetchAccessToken = useCallback(async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      setAuth({ token: token });
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    fetchAccessToken();
  }, [fetchAccessToken]);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
