import Sidebar, { SidebarGroup } from "../../../components/Sidebar";

const items: Array<SidebarGroup> = [
  {
    title: "API",
    links: [
      { name: "Introduzione", path: "/docs/overview" },
      { name: "Autenticazione", path: "/docs/authentication" },
      { name: "Progetti", path: "/docs/projects" },
      { name: "Conversazioni", path: "/docs/conversations" },
      { name: "Addestramento  ", path: "/docs/trainings" },
    ],
  },
];

const PublicSidebar = ({ width }: PublicSidebarProps) => {
  return <Sidebar topItems={items} bottomItems={[]} width={width} />;
};

interface PublicSidebarProps {
  width: number;
}

export default PublicSidebar;
