import UseCase from "../../core/app/usecase";
import Summary from "../entities/summary";
import StoneRepository from "../repositories/stone";

class GetAllStones implements UseCase<string, Summary[]> {
  private repository: StoneRepository;

  constructor(repository: StoneRepository) {
    this.repository = repository;
  }

  async Call(): Promise<Summary[]> {
    return this.repository.getAll();
  }
}

export default GetAllStones;
