import EndpointDocumentation from "./EndpointDocumentation";

const GetProjectTrainings = () => {
  const data = {
    title: "Lista training di un progetto",
    path: "GET /projects/<projectId>/trainings",
    description: "Ottiene gli identificativi dei training di un progetto",
    responseBody: {
      trainings: [
        {
          id: "training id",
          name: "training name",
        },
      ],
    },
    params: [
      {
        name: "projectId",
        description:
          "Rappresenta l'ID del progetto per la quale si vuole ottenere la lista dei training",
      },
    ],
  };

  return <EndpointDocumentation {...data} />;
};

export default GetProjectTrainings;
