import { withAuthenticationRequired } from "@auth0/auth0-react";
import SidebarLeftLayout from "../../../layout/SidebarLeftLayout";
import TopBar from "../../../components/TopBar";
import LoadingView from "../../public/views/LoadingView";
import DashboardSidebar from "../components/DashboardSidebar";

function DashboardView() {
  const sidebarWidth = 250;

  return (
    <SidebarLeftLayout
      topbar={<TopBar />}
      sidebar={<DashboardSidebar width={sidebarWidth} />}
      sidebarWidth={sidebarWidth}
    />
  );
}

export default withAuthenticationRequired(DashboardView, {
  onRedirecting: () => <LoadingView />,
});
