import { Box, Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import MessageBubble from "../components/MessageBubble";
import ConversationTopBar from "../components/ConversationTopBar";
import ConversationMessageBar from "../components/ConversationMessageBar";
import { v4 as uuidv4 } from "uuid";
import { useConversationDetailViewModel } from "../../../hooks/conversationDetailsViewModel";
import { useEffect, useRef } from "react";

const ConversationContentView = () => {
  const { id } = useParams();
  const { sendMessage, conversation } = useConversationDetailViewModel(id!);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const renderMessages = () => {
    return conversation?.messages.map((msg) => (
      <MessageBubble
        key={uuidv4()}
        isSender={msg.role == "user" ? true : false}
        message={msg.content}
      />
    ));
  };

  const handleSend = (message: string) => {
    if (id) {
      const data = {
        message: message,
      };

      sendMessage(JSON.stringify(data));
    }
  };

  const handleRenderConversationTitle = () => {
    const title = conversation ? conversation.title : "...";
    return <ConversationTopBar title={title} />;
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversation?.messages]);

  return (
    <Box height="calc(100vh - 7rem)">
      <Flex direction="column" height="100%" w="100%">
        {handleRenderConversationTitle()}
        <Box flex="1" p={5} overflow="scroll">
          <Box display="flex" flexDirection="column">
            {renderMessages()}
            <div ref={messagesEndRef} />
          </Box>
        </Box>
        <ConversationMessageBar
          onSend={(message: string) => handleSend(message)}
        />
      </Flex>
    </Box>
  );
};

export default ConversationContentView;
