import { Flex, Spinner } from "@chakra-ui/react";

const LoadingView = () => {
  return (
    <Flex h="100vh" w="100vw" justify="center" align="center">
      <Spinner size="xl" />
    </Flex>
  );
};

export default LoadingView;
