import { Box, Button, Flex, VStack, useColorModeValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ConversationItem from "./ConversationItem";
import { FiPlus, FiGrid } from "react-icons/fi";
import Conversation from "../../../../domain/entities/conversation";
import Summary from "../../../../domain/entities/summary";

const ConversationSidebar = ({
  conversations,
  openDialog,
}: ConversationSidebarProps) => {
  const renderConversations = () => {
    return conversations.map((conversation: Conversation) => {
      return (
        <Link key={conversation.id} to={`/conversations/${conversation.id}`}>
          <Box mx={2}>
            <ConversationItem
              stone={{
                id: conversation.id,
                name: conversation.title,
                description: conversation.projectId,
              }}
            />
          </Box>
        </Link>
      );
    });
  };

  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", sm: 300, md: 300, lg: 300, xl: 300 }}
      pos="fixed"
      height="calc(100vh - 8rem)"
    >
      <Flex as="nav" direction="column" height="100%" pt="5" w="100%">
        <Box w="full" overflow="scroll">
          <VStack align="stretch">{renderConversations()}</VStack>
        </Box>
        <Box flex="1" />
        <Box w="full" px="2">
          <VStack align="stretch">
            <Link to="/dashboard" style={{ display: "block", width: "100%" }}>
              <Button
                colorScheme="teal"
                leftIcon={<FiGrid />}
                size="sm"
                width="100%"
                variant="outline"
                my={1}
              >
                <span>Dashboard</span>
              </Button>
            </Link>
            <Button
              colorScheme="teal"
              leftIcon={<FiPlus />}
              size="sm"
              my={1}
              width="100%"
              onClick={openDialog}
            >
              New Conversation
            </Button>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

interface ConversationSidebarProps {
  conversations: Conversation[];
  openDialog: () => void;
  stones: Summary[];
}

export default ConversationSidebar;
